<template>
    <div class="modal-party-interest-info modal-fullscreen">
        <div class="head">
            <div class="flex-row flex-between items-start">
                <div class="left flex-fill">
                    <nav class="tabs m-t-12">
                        <div
                            class="tab"
                            :class="{ selected: item.selected }"
                            v-for="item in tabItems"
                            :key="item.label"
                            @click="onClickTab(item)"
                        >
                            <div class="content">
                                <p class="label" v-html="$translate(item.label)" />
                            </div>
                        </div>
                    </nav>
                </div>
                <i @click="$emit('close')" class="material-icons">close</i>
            </div>
            <div class="timestamp">
                {{ notice.created_at | formatDate('YY.MM.DD') }}
            </div>
        </div>
        <div class="hr" />
        <div class="body">
            <div class="info-content" v-if="this.selectedTab === 'superInterest'">
                <img class="premium-agent-inner flex-wrap" src="@/assets/images/super_interest_info.png" />
            </div>
            <div class="info-content" v-if="this.selectedTab === 'normalInterest'">
                <img class="premium-agent-inner flex-wrap" src="@/assets/images/normal_interest_info.png" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalPartyInterestInfo',
    props: ['options'],
    computed: {
        notice() {
            return (this.options || {}).notice || {}
        },
    },
    data: () => ({
        selectedTab: 'superInterest',
        tabItems: [
            {
                type: 'superInterest',
                label: 'SUPER_INTEREST',
                selected: true,
            },
            {
                type: 'normalInterest',
                label: 'NORMAL_INTEREST',
                selected: false,
            },
        ],
        loading: false,
        lastScrollTop: 0,
        // filterState: [],
        // filterType: ['group_meeting', 'friend_meeting'],
    }),
    methods: {
        onClickTab(item) {
            item.selected = true

            this.tabItems.forEach(tab => {
                if (tab.type !== item.type) {
                    tab.selected = false
                }
            })

            this.selectedTab = item.type
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-party-interest-info {
    padding: 0 !important;

    width: 80%;
    margin-left: 10%;

    .content {
        font-size: 13.5px;
    }

    .head {
        padding: 20px;

        .title {
            margin: 4px 0 8px;
            color: $grey-09;
            font-size: 18px;
        }

        .timestamp {
            font-size: 14px;
            color: $grey-05;
        }

        .material-icons {
            margin: 0;
        }
    }

    .hr {
        width: 100%;
        height: 1px;
        margin: auto;
        background: $grey-02;
    }

    .body {
        // padding: 0 20px;
        line-height: 24px;
        max-height: calc(100vh - 120px);
        overflow-y: auto;
    }

    .info-content {
        img {
            width: 100%;
            height: 110%;
        }
    }
}
</style>
